<template>
  <v-autocomplete
    v-model="equipe"
    :items="equipes"
    :loading="loadingEquipe"
    @click:clear="clearColaborador"
    dense
    clearable
    solo
    flat
    hide-details
    hide-no-data
    outlined
    class=""
    item-text="nome"
    item-value="id"
    label="Colaborador"
    prepend-inner-icon="mdi-account-group"
  >
  </v-autocomplete>
</template>

<script>
import { fetchEquipe } from "@/api/gestor/empresa_equipe.js";

export default {
  name: "Clientes",

  data() {
    return {
      loadingEquipe: true,
      equipe: null,
      equipes: [],
    };
  },

  watch: {
    equipe() {
      if (this.equipe) {
        this.$store.commit("Filtros/UPDATE_COLABORADOR", this.equipe);
      }
    },
  },

  methods: {
    clearColaborador() {
      this.$store.commit("Filtros/UPDATE_COLABORADOR", null);
      this.equipe = null;
    },

    getEquipes() {
      this.loadingEquipe = true;
      return fetchEquipe()
        .then((response) => {
          this.equipes = response;
          this.loadingEquipe = false;
        })
        .catch(() => {
          this.loadingEquipe = false;
        });
    },
  },

  async mounted() {
    await this.getEquipes();
    if (this.$route.query.colaborador_id) {
      let equipe = this.equipes.find(
        (item) => item.id == this.$route.query.colaborador_id
      );
      this.equipe = equipe.id;
    }
  },
};
</script>

<style></style>
